
$black: #212121;
$white: #fff;
$lighterGray: #fafafa;
$lightGray: #fefefe;
$gray: #e0e0e0;
$darkGray: #616161;
$primaryBlue: #1652f0;
$hoverBlue: #154de0;
$red: #d9605a;
$mainDark: #191f2b;
$lightDark: #222a37;

// font weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$heavy: 800;
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 80%;
  margin: 0 auto;
  font-family: 'Nucleo Outline';
  text-align: center;

  a {
    width: 100px;
    padding: 1rem;
    color: $white;
    background: #fc466b; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #3f5efb,
      #fc466b
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #3f5efb,
      #fc466b
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 0.5rem;
    animation: float 3s ease-in-out infinite;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0);
    transition: all 0.25s ease 0s;

    &:hover {
      opacity: 0.9;
      transition: all 0.25s ease 0s;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    }

    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-10px);
      }
      100% {
        transform: translatey(0px);
      }
    }
  }

  p {
    &:nth-of-type(2) {
      font-size: 12px;
    }
  }
}
