html {
    position: relative;
    min-height: 100%;
}
body {
    margin-bottom: 105px; /* Margin bottom by footer height */
    margin-top: 15px;
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}
@media print {
    .hidden-print {
        display: none !important;
    }
}
